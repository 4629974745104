import { useRef, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useRequest } from 'ahooks'
import { produce } from 'immer'
import dayjs from 'dayjs'
import { Badge, Button, Input, message, Modal, Radio } from 'antd'
import type { ColumnsType } from 'antd/lib/table'
import { PlusOutlined } from '@ant-design/icons'

import { CusTable, TCusTableIns } from '@/components/CusTable'
import { SPIN_DELAY } from '@/components/Loading'
import { useControllerRef } from '@/hooks/useAbortController'
import { DEFAULT_PAGE_SIZE, getDefaultPaginationProps } from '@/utils/pagination'
import { AddWhitelistModal } from './AddWhitelistModal'
import { apiApprove, apiGetRecords } from './apis'
import { IRecord, IReqData, IRes } from './interface'
import { popupSlot } from './utils'
import styles from './styles.module.less'

export const WhiteList = () => {
  const { t } = useTranslation()
  const [processStatus, setProcessStatus] = useState<IReqData['processStatus']>(0)
  const [keyword, setKeyword] = useState<IReqData['keyword']>()
  const [page, setPage] = useState<IReqData['page']>(1)
  const [size, setSize] = useState<IReqData['size']>(DEFAULT_PAGE_SIZE)

  const reqData: IReqData = { processStatus, keyword, page, size }
  const tableRef = useRef<TCusTableIns>(null)
  const controllerRef = useControllerRef()
  const {
    loading,
    data,
    params: [param],
    run: getList,
    mutate,
  } = useRequest<IRes, [IReqData]>(
    req => {
      controllerRef.current.abort()
      controllerRef.current = new AbortController()
      return apiGetRecords(req, controllerRef.current.signal)
    },
    {
      defaultParams: [reqData],
      onBefore: () => CusTable.scrollToTop(tableRef.current),
    },
  )

  const changeRecord = (id: number, changedValues: Partial<IRecord>) => {
    mutate(oldData => {
      if (!oldData) return oldData
      return produce(oldData, draft => {
        for (const item of draft.records) {
          if (item.id === id) {
            Object.assign(item, changedValues)
            return
          }
        }
      })
    })
  }
  const deleteRecord = (id: number) => {
    mutate(oldData => {
      if (!oldData) return oldData
      return produce(oldData, draft => {
        let index = -1
        for (const item of draft.records) {
          index++
          if (item.id === id) {
            draft.records.splice(index, 1)
            return
          }
        }
      })
    })
  }

  const columns: ColumnsType<IRecord> = [
    {
      title: t('2-common.you-xiang'),
      render: (_v, { email: v }) => v,
    },
    {
      title: t('WhiteList.applicationTime'),
      render: (_v, { applyTime: v }) => dayjs(v).format($F_YMDHms),
    },
    {
      title: t('WhiteList.processStatus'),
      render: (_v, { approvalStatus: v }) => {
        if (v === 0) {
          return <Badge color={$C_STATUS_DEFAULT} text={t('WhiteList.pendingApproval')} />
        }
        if (v === 1) {
          return <Badge color="green" text={t('WhiteList.agreed')} />
        }
        if (v === 2) {
          return <Badge color="red" text={t('WhiteList.rejected')} />
        }
      },
    },
    {
      title: t('2-common.cao-zuo'),
      render: (_v, { id, email, approvalStatus }) => {
        if (approvalStatus !== 0) return
        return (
          <>
            <Button
              type="link"
              onClick={() => {
                Modal.confirm({
                  title: t('WhiteList.confirmAgree'),
                  content: email,
                  onOk: async () => {
                    await apiApprove(id, 1)
                    message.success(t('2-common.cao-zuo-cheng-gong'))
                    if (processStatus === 0) deleteRecord(id)
                    else changeRecord(id, { approvalStatus: 1 })
                  },
                })
              }}
            >
              {t('WhiteList.agree')}
            </Button>
            <Button
              type="link"
              danger
              onClick={() => {
                Modal.confirm({
                  title: t('WhiteList.confirmRejection'),
                  content: email,
                  onOk: async () => {
                    await apiApprove(id, 2)
                    message.success(t('2-common.cao-zuo-cheng-gong'))
                    if (processStatus === 0) deleteRecord(id)
                    else changeRecord(id, { approvalStatus: 2 })
                  },
                })
              }}
            >
              {t('WhiteList.reject')}
            </Button>
          </>
        )
      },
    },
  ]

  return (
    <div className={styles.box}>
      <div className={styles.header}>
        <div className={styles.header_filter}>
          <Radio.Group
            className={styles.header_filter_status}
            buttonStyle="solid"
            value={processStatus}
            onChange={e => {
              const val = e.target.value
              setProcessStatus(val)
              setPage(1)
              getList({ ...reqData, processStatus: val, page: 1 })
            }}
          >
            <Radio.Button value={0}>{t('WhiteList.pendingApproval')}</Radio.Button>
            <Radio.Button value={undefined}>{t('2-common.quan-bu')}</Radio.Button>
          </Radio.Group>
          <Input.Search
            className={styles.header_filter_kw}
            placeholder={t('WhiteList.emailKeyword')}
            enterButton
            allowClear
            onChange={e => setKeyword(e.target.value.trim())}
            onSearch={(val, _e, info) => {
              val = val.trim()
              if (info?.source === 'clear' && !param?.keyword) return
              setPage(1)
              getList({ ...reqData, keyword: val, page: 1 })
            }}
          />
        </div>
        <div className={styles.header_action}>
          <Button type="primary" icon={<PlusOutlined />} onClick={AddWhitelistModal.open}>
            {t('WhiteList.addWhiteList')}
          </Button>
        </div>
      </div>

      <div className={styles.table}>
        <CusTable
          sticky
          rowKey="id"
          loading={{ spinning: loading, delay: SPIN_DELAY }}
          dataSource={data?.records}
          columns={columns}
          ref={tableRef}
          verticalFlexLayout
          pagination={{
            ...getDefaultPaginationProps(data?.total || 0),
            current: page,
            pageSize: size,
            onChange: (page, size) => {
              setPage(page)
              setSize(size)
              getList({ ...reqData, page, size })
            },
          }}
        />
      </div>

      <popupSlot.Slot />
    </div>
  )
}
