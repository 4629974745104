import { useState } from 'react'
import { useTranslation } from 'react-i18next'
import { Input, message, Modal } from 'antd'

import { apiAddWhiteList } from './apis'
import { popupSlot } from './utils'

const updatePopup = popupSlot.insert(null)

export const AddWhitelistModal = Object.assign(
  ({ destroy }: { destroy: () => void }) => {
    const { t } = useTranslation()
    const { open, onHide, afterOpenChange } = popupSlot.useAntdPopupAnimation(destroy)
    const [content, setContent] = useState('')
    const [loading, setLoading] = useState(false)

    return (
      <Modal
        title={t('WhiteList.addWhiteList')}
        onOk={async () => {
          try {
            setLoading(true)
            await apiAddWhiteList(content.trim())
            message.success(t('2-common.cao-zuo-cheng-gong'))
            onHide()
          } catch (err) {
            setLoading(false)
            throw err
          }
        }}
        okButtonProps={{ loading, disabled: !content.trim() }}
        open={open}
        onCancel={onHide}
        afterOpenChange={afterOpenChange}
      >
        <Input.TextArea
          placeholder={t('WhiteList.emailInputTip')}
          rows={6}
          value={content}
          onChange={e => setContent(e.target.value)}
        />
      </Modal>
    )
  },
  {
    open: () => {
      updatePopup(<AddWhitelistModal destroy={() => updatePopup(null)} />)
    },
  },
)
