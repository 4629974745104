import axios, { AxiosRequestConfig } from 'axios'
import qs from 'qs'

import { IReqData, IRes } from './interface'
import './__mock__'

/** 获取白名单管理列表 */
export const apiGetRecords = async (reqData: IReqData, signal?: AxiosRequestConfig['signal']): Promise<IRes> => {
  return axios.get('/linkhub_oms/white_list/approval/record', { params: reqData, signal }).then(({ $data }) => {
    return {
      ...$data,
      records: $data?.records || [],
    }
  })
}

/** 同意&拒绝 */
export const apiApprove = async (
  id: number,
  /** 1-同意；2-拒绝 */
  approvalStatus: 1 | 2,
) => {
  await axios.put(`/linkhub_oms/white_list/approval/${id}`, qs.stringify({ approvalStatus }))
}

/** 添加白名单 */
export const apiAddWhiteList = async (content: string) => {
  await axios.post('/linkhub_oms/white_list/batch', { content })
}
